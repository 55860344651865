import { Link } from "gatsby"
import React from "react"
import { FaPercent } from "react-icons/fa"

const MainMenuItem = ({ item, setOpen, parent, catId, path, pages }) => {
  let Icon
  switch (item.url) {
    case "doprodej":
      Icon = FaPercent
      break
    case "":
      // code block
      break
    default:
    // code block
  }

  return (
    <>
      {/* {!item.children ? ( */}
      <>
        <div className=" ">
          <div
            className={
              " flex  lg:text-gray-100 " +
              (Icon
                ? " text-red-600  lg:ml-3 lg:border-l lg:border-l-gray-200  lg:pl-2"
                : " text-gray-600  ")
            }
          >
            <Link
              to={!pages ? "/produkty/" + item.url + "/" : item.url}
              partiallyActive={item.url === "" ? false : true}
              onClick={() => setOpen(false)}
              className={
                " group w-full rounded-md inline-flex items-center  text-sm font-medium  focus:outline-none   px-2 py-2  hover:bg-yellow-400 hover:text-gray-800  space-x-2 " +
                (path && path.length > 0 && path.includes(item.url)
                  ? "bg-yellow-400 text-black "
                  : "")
              }
              activeClassName="bg-yellow-400 text-black"
            >
              {Icon && (
                <div className="w-5 h-5 p-1 flex items-center justify-center rouded-full">
                  <Icon className="w-4 h-4" />
                </div>
              )}
              <span>{item.menuName}</span>
            </Link>
          </div>
        </div>
      </>
      {/* ) : (
        <div className="lg:relative  text-sm">
          <Popover>
            {({ open, close }) => (
              <>
                <div className=" flex lg:relative ">
                  <Popover.Button
                    className={classNames(
                      parent === parseInt(item.id) ||
                        catId === parseInt(item.id) ||
                        (path && path.length > 0 && path.includes(item.url))
                        ? "bg-amber-500 text-white"
                        : open
                        ? " bg-amber-500 text-white "
                        : "text-gray-600  lg:text-gray-100 ",
                      "group  rounded-md flex justify-between w-full lg:w-auto lg:inline-flex items-center font-medium hover:text-white focus:outline-none   px-2 py-2  hover:bg-amber-500  "
                    )}
                  >
                    <span>{item.menuName}</span>
                    <ChevronDownIcon
                      className={classNames(
                        "ml-1 h-5 w-5 group-hover:text-white hidden lg:block opacity-50"
                      )}
                      aria-hidden="true"
                    />
                    <ChevronRightIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-1 h-5 w-5 group-hover:text-white block lg:hidden"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                </div>

                <Transition
                  show={open}
                  enter=" duration-150 ease-out "
                  enterFrom="opacity-0 "
                  enterTo="opacity-100 "
                  leave=" duration-150 ease-in "
                  leaveFrom="opacity-100 "
                  leaveTo="opacity-0 "
                >
                  <div
                    className="  fixed w-full  top-0 h-full inset-0 bg-black bg-opacity-25 lg:hidden"
                    aria-hidden="true"
                    onClick={() => close()}
                  ></div>
                </Transition>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-x-full lg:translate-x-0 lg:scale-95"
                  enterTo="opacity-100 translate-x-0  lg:scale-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-x-0 lg:scale-100"
                  leaveTo="opacity-0 translate-x-full lg:translate-x-0 lg:scale-95"
                >
                  <Popover.Panel
                    static
                    className="absolute h-screen lg:h-auto   top-0 lg:top-10 right-0 left-16 lg:left-0 lg:absolute z-50 inset-x-0 transition transform lg:shadow-lg w-80  lg:w-80"
                  >
                    <div className="h-full ">
                      <nav className="h-full bg-white  border pb-10 lg:rounded-md lg:px-3">
                        <div className="w-full py-5 lg:hidden">
                          <button className="px-3 w-full" onClick={close}>
                            <ChevronLeftIcon className="w-10 h-10 text-gray-500" />
                          </button>
                        </div>
                        <Link
                          onClick={() => closeMenu(close)}
                          to={"/produkty/" + item.url + "/"}
                          partiallyActive={item.url === "" ? false : true}
                          className={
                            "p-3 mt-2 text-xl flex items-center rounded-md  font-medium text-gray-900 hover:bg-gray-100 transition ease-in-out duration-150 w-full "
                          }
                          activeClassName="  hover:text-amber-800   "
                        >
                          {item.menuName}
                        </Link>
                        <div>
                          <ul className="my-3 ">
                            {item.children.map((page, i) => (
                              <li key={i} className="flow-root">
                                <Link
                                  onClick={() => closeMenu(close)}
                                  to={"/produkty/" + page.url + "/"}
                                  partiallyActive={
                                    page.url === "" ? false : true
                                  }
                                  className={
                                    "p-3 flex items-center rounded-md  font-medium  hover:bg-gray-100 transition ease-in-out duration-150 w-full " +
                                    (path &&
                                    path.length > 0 &&
                                    path.includes(page.url)
                                      ? "text-amber-700 font-bold"
                                      : "text-gray-900")
                                  }
                                  activeClassName=" text-amber-700 hover:text-amber-800 border-blue-600 font-bold "
                                >
                                   
                                  <span className="ml-4">{page.menuName}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      )} */}
    </>
  )
}

export default MainMenuItem
