import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useContext } from "react"
import PageContext from "../../context/PageContext"

export const SubCats = ({ catData }) => {
  const [catList, setCatList] = useState([])

  const [context] = useContext(PageContext)
  const cats = context?.menu
  const menu = context?.menu
  const parent = parseInt(catData.parentId)

  function importAll(r) {
    const keys = r.keys()
    const values = keys.map(r)
    let newValues = []

    keys.forEach(function (val, keyIdx) {
      var tmp = {
        name: val.replace(/\/|.svg|\./g, ""),
        function: values[keyIdx],
      }

      newValues[keyIdx] = tmp
    })

    return newValues
  }

  useEffect(() => {
    const icons = importAll(require.context("../../icons/", false, /\.(svg)$/))

    function menuItems() {
      let newItems = []
      let subCats = []
      if (cats && cats.length !== 0 && catData) {
        // nejsou podkategorie, zjistime nadrazene Kategorie
        if (parent === 1) {
          menu.forEach(item => {
            if (catData._id === item._id) {
              subCats = item.children
            }
          })
        } else {
          menu.forEach(item => {
            if (parseInt(catData.parentId) === item._id) {
              subCats = item.children
            }
          })
        }
      }

      subCats &&
        subCats.forEach(menuItem => {
          let newMenuItem = { ...menuItem }

          icons.forEach(icon => {
            if (menuItem.url === icon.name) {
              newMenuItem.icon = icon.function
            }
          })
          newItems.push(newMenuItem)
        })
      return newItems
    }

    let newCats = menuItems()
    setCatList(newCats)

    // eslint-disable-next-line
  }, [catData])

  return (
    <>
      {catList && catList.length > 0 && (
        <nav
          aria-label="Subcategories"
          className="text-sm font-bold pb-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 text-blue-700"
        >
          {catList &&
            catList.map(page => (
              <Link
                key={page.menuName}
                to={"/produkty/" + page.url + "/"}
                partiallyActive={true}
                className=" p-3 space-x-2 bg-blue-700 bg-opacity-10  rounded-md  border flex items-center justify-center  hover:border-blue-700 hover:text-white hover:bg-blue-700   "
                activeClassName="border-blue-700 text-white  bg-blue-700 bg-opacity-100"
                aria-current={page.current ? "page" : undefined}
              >
                {page.icon && (
                  <span>
                    <page.icon className="w-5 h-5" fill="currentColor" />
                  </span>
                )}
                <span>{page.menuName}</span>
              </Link>
            ))}
        </nav>
      )}
    </>
  )
}
