import React, {createContext, useReducer} from "react";
import {Reducer} from "./reducer";

const initialState = {
    items: [],
    page: 1,
    category: null,
    query: 'goods/items/',
    params: [],
    error: null,
    loading: false
};

export const Store = ({children}) => {



    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <GoodsContext.Provider value={[state, dispatch]}>
            {children}
        </GoodsContext.Provider>
    )
};

export const GoodsContext = createContext(initialState);
