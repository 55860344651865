module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/foda.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1eb483e6074ea72b6b3abe34ea77b315"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FÖDA","short_name":"FÖDA","start_url":"/","background_color":"#ffffff","theme_color":"#B91C1C","display":"minimal-ui","icon":"src/images/foda.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1eb483e6074ea72b6b3abe34ea77b315"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/emocio/foda.cz/web/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
