import React, { useRef, useContext, useState } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import { Modal } from "./layout/modal"
import { BreadCrumb } from "./layout/BreadCrumb"
import PageContext from "../context/PageContext"
import ProductsNew from "./goods/ProductsNew"
import CookieConsent from "react-cookie-consent"

import {
  AiOutlineSafetyCertificate as History,
  AiOutlinePercentage,
} from "react-icons/ai"
import { default as Speed } from "../icons/speed.svg"

import { Feature } from "./elements/Feature"
import Footer from "./footer"

const Layout = ({ children, location, order }) => {
  const consentRef = useRef()

  const [context] = useContext(PageContext)
  const searchString = context?.searchString

  const [cookieVisible, setCookieVisible] = useState("byCookieValue")

  let path
  let cat
  if (location) {
    path = location?.pathname?.split("/")
  }

  if (path && path[2] !== "strana") {
    cat = path[2]
  }

  return (
    <>
      <div className="relative shadow-md">
        <Header location={location} cat={cat} order={order} />
        <main className={"relative  pb-10  lg:pb-32 " + (!order && " lg:pt-0")}>
          {location?.pathname !== "/" && <BreadCrumb />}

          {searchString ? (
            <>
              <ProductsNew location={location} />
            </>
          ) : (
            children
          )}
        </main>
      </div>

      <div className="bg-gray-100 py-5">
        <div className=" max-w-7xl mx-auto p-5 py-2  ">
          <div className="border rounded-md bg-white grid md:grid-cols-3 divide-y md:divide-x md:divide-y-0 shadow-md">
            <Feature
              text={"Nad 1200 Kč rozvoz po Praze zdarma"}
              Icon={AiOutlinePercentage}
            />
            <Feature
              text={"Doručení obvykle následující pracovní den"}
              Icon={Speed}
            />
            <Feature text={"Čerstvé zboží každých 14 dní"} Icon={History} />
          </div>
        </div>
      </div>
      <footer
        className="bg-gray-200 text-gray-700"
        aria-labelledby="footerHeading"
      >
        <Footer />
        <div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:pb-16 lg:px-8 pt-6">
          <div className="  flex  items-center  justify-between">
            <div className="flex space-x-6 order-2">
              <button type="button" onClick={() => setCookieVisible("show")}>
                Nastavení cookies
              </button>
            </div>
            <p className=" text-base   mt-0  order-1">
              &copy; FÖDA {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </footer>
      <Modal />
      <CookieConsent
        location="bottom"
        buttonText={
          <div
            className="rounded-md bg-gray-100 px-6 py-2  hover:bg-gray-300"
            onClick={() => setCookieVisible("byCookieValue")}
          >
            Souhlasím
          </div>
        }
        buttonWrapperClasses="w-full grid grid-cols-2 gap-4"
        declineButtonText={
          <div
            className="rounded-md bg-gray-100 opacity-80 px-6 py-2 hover:bg-gray-300  "
            onClick={() => setCookieVisible("byCookieValue")}
          >
            Nesouhlasím
          </div>
        }
        enableDeclineButton
        flipButtons={true}
        //visible={cookieVisible}
        visible={"hidden"}
        disableStyles
        overlay={true}
        overlayClasses=" bg-gradient-to-b from-transparent to-black  z-[1000] h-[300px]  bottom-0 w-full fixed"
        cookieName="gatsby-gdpr-google-tagmanager"
        containerClasses="fixed lg:right-5 lg:left-auto  bg-gradient-to-br from-sky-600 to-sky-800  z-[1000] w-auto  lg:w-[600px]    lg:border border-gray-400 p-8 lg:rounded-md shadow-lg  lg:my-10 space-y-4"
      >
        <div className="text-2xl   text-white mb-3 pb-3 border-b border-white border-opacity-20">
          Používáme cookies!
        </div>
        <div className=" text-sm text-gray-200">
          Základní cookies potřebujeme k zajištění správného fungování stránek a
          volitelné k lepšímu pochopení toho, jak s nimi pracujete. Ty druhé se
          aktivují po stisku tlačítka Souhlasím.
        </div>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
