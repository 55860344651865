const DEFAULT_QUERY = 'goods/items/';

export const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload.error,
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: action.payload.loading,
            };
        case 'CLEAR_ERROR':
            return {
                ...state,
                error: null,
            };
        case 'CHANGE_CATEGORY':
            return {
                ...state,
                page: 1,
                category: action.payload.category,
                items: [],
                query: DEFAULT_QUERY + action.payload.category + '/',
            };
        case 'CHANGE_PARAMS':
            return {
                ...state,
                page: 1,
                params: action.payload.params,
                items: [],
            };
        case 'CLEAR_CATEGORY':
            return {
                ...state,
                page: 1,
                category: null,
                items: [],
                query: DEFAULT_QUERY,
            };
        case 'SET_ITEMS':
            return {
                ...state,
                items: [...action.payload.items],
                hasMore: action.payload.hasMore,
                tags: action.payload.tags
            };
        case 'ADD_PAGE':
            return {
                ...state,
                page: state.page + 1,
                items: [...state.items, ...action.payload.items],
            };
        case 'NEXT_PAGE':
            return {
                ...state,
                page: state.page + 1,
                items: [action.payload.items],
            };
        case 'PREV_PAGE':
            return {
                ...state,
                page: state.page - 1,
                items: [action.payload.items],
            };
        default:
            return state;
    }
};
