import React from "react"

export const Feature = ({ text, color, Icon }) => {
  let style
  switch (color) {
    case "green":
      style = "text-green-600 border-green-600 font-bold "
      break
    case "orange":
      style = "text-orange-600 border-orange-600 font-bold "
      break
    case "gray":
      style = "text-gray-400 border-gray-200"
      break
    default:
    // code block
  }
  return (
    <>
      <div className="flex lg:justify-center items-center p-5 space-x-4 ">
        {Icon && (
          <Icon className="w-7 h-7 text-blue-700 " fill="currentColor" />
        )}
        <div className={" mr-1 w-auto inline  border-transparent  " + style}>
          {text}
        </div>
      </div>
    </>
  )
}
