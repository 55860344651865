import { gql } from "@apollo/client"

export const GOODS_LIST = gql`
  query goodsCategory(
    $cat: String
    $itemsPerPage: Int
    $page: Int
    $top: String
    $orderBy: [GoodFilter_order]
  ) {
    goods(
      category: $cat
      order: $orderBy
      itemsPerPage: $itemsPerPage
      top: $top
      page: $page
      visible: "1"
    ) {
      paginationInfo {
        totalCount
        lastPage
      }
      collection {
        _id
        name
        img
        alias
        priceVat
        supRrp
        quantity
        description
        size
        note
      }
    }
    goodCategories(url: $cat) {
      _id
      menuName: name
      content
      parentId
      lft
      url
    }
  }
`

export const GOODS_SEARCH = gql`
  query goodsCategory($search: String!, $orderBy: [GoodFilter_order]) {
    goods(name: $search, order: $orderBy, visible: "1") {
      collection {
        _id
        name
        img
        alias
        priceVat
        supRrp
        quantity
        description
        size
        note
      }
    }
  }
`

export const GOOD_DETAIL = gql`
  query goodDetail($id: ID!) {
    good(id: $id) {
      _id
      quantity
      priceVat
      visible
      note
    }
    allCats: goodCategories {
      _id
      menuName: name
      content
      parentId
      lft
      url
    }
  }
`
