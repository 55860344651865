
import React from "react";

export const LoadingProducts = (props) => {

    const items = []

    const Element = () => {
        return (
        
        <div className="group relative bg-white  rounded-md overflow-hidden  ">
            <div className="relative w-full sm:min-h-56 lg:min-h-72 lg:h-72 rounded-md overflow-hidden ">
                <div className="w-full h-full aspect-w-1 aspect-h-1   lg:aspect-none bg-gray-100">
                    <div className="w-full h-full  object-center object-cover ">
                        
                    </div>
                    
                </div>
                <div className="absolute top-0 right-0 left-0 bottom-0 inset-0 w-full h-full animate-shimmer " style={{background:"linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)", backgroundSize: "1000px 100%"}} />
            </div>
            <div className="w-full ">
                <div className="pt-3  ">
                    <div className="relative rounded-md overflow-hidden">
                        <h3 className="font-bold text-gray-600 text-sm bg-gray-100 rounded-md h-7"> 
                        </h3>
                        <div className="absolute top-0 right-0 left-0 bottom-0 inset-0 w-full h-full animate-shimmer " style={{background:"linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)", backgroundSize: "1000px 100%"}} />


                    </div>
                 
                <div className="  text-xs items-center mt-3 rounded-md overflow-hidden relative">
                    <p className=" text-base text-sky-700 font-bold bg-gray-100 rounded-md h-7 w-full"></p> 
                        <div className="absolute top-0 right-0 left-0 bottom-0 inset-0 w-full h-full animate-shimmer " style={{background:"linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%)", backgroundSize: "1000px 100%"}} />

                </div>
            </div>
                 
            </div> 
            
        </div>

        )
    }

    for (let i = 0; i < 4; i++) {
        items.push(i)
    }
    
    return(
        <>
            <div className="  w-full">
                <div className="mt-6 grid grid-cols-2 gap-y-8 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8 max-w-7xl mx-auto  px-5">
                    {items.map((item,itemIdx) => (
                        <Element key={itemIdx} />
                    ))}
                </div>
            </div>
        </>
    )
}