import React from "react"
import { Transition } from "@headlessui/react"
import { default as Logo } from "../../images/symbol.svg"

export const Loading = props => {
  const loading = props.loading

  //backdrop-filter backdrop-blur-sm
  return (
    <Transition
      show={loading}
      className="fixed z-50 inset-0  overflow-y-auto  h-full backdrop-filter backdrop-blur-sm ease-in-out duration-300"
    >
      <div className="flex flex-wrap  items-end overflow-y-auto h-full  text-center sm:block sm:p-0  ">
        <Transition.Child
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          enter="ease-out duration-50"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-50"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute top-0 bottom-0 inset-0 bg-white opacity-60  h-full  w-full " />
        </Transition.Child>
        <Transition.Child></Transition.Child>
        <span
          className={"hidden sm:inline-block sm:align-middle "}
          aria-hidden="true"
        >
          &#8203;
        </span>

        <Transition.Child
          className="inline-block align-bottom  rounded-lg  text-left overflow-hidden  transform transition-all sm:align-top sm:max-w-4xl w-full h-full pb-20 "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          enter="ease-out duration-150"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="flex flex-1 flex-wrap my-10 py-5 text-sky-700  w-full h-full items-center justify-center  ">
            <div className=" text-center flex flex-col justify-center items-center bg-white border rounded-md w-1/2 py-20 relative overflow-hidden shadow-lg">
              <span className="mb-5 text-gray-400">Hned to bude</span>
              <svg
                className="animate-spin w-10 inline"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <img
                src={Logo}
                className="w-24 h-24  mt-10  "
                alt="FÖDA švédské potraviny"
              />
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  )
}
