import React, { Fragment, useContext, useEffect, useState }  from "react";  
import { Transition, Dialog } from "@headlessui/react";  
import PageContext from "../../context/PageContext";
 
export const ModalLayout = (props) => {
    return (
        <>
        <div className="  rounded-b-lg   " >

            <div className="  p-5 lg:p-8 bg-gray-100 border-b  rounded-t-lg ">
                    <div className=" lg:grid lg:grid-cols-2 items-center justify-between flex-wrap sm:flex-nowrap">
                         
                        <div className="  flex-shrink-0 ">
                            <h3 className="text-lg leading-6 font-medium text-gray-600">
                                {props.title}
                            </h3>
                            
                        </div>
                    </div>
                </div>
                
                <div className=" p-5 lg:p-8 align-bottom rounded-b-lg  text-left transform transition-all  sm:align-middle sm:w-full  bg-white ">
                    {props.children}
                </div>
                
            </div>
            
        </>
    )
}

export const Modal = (props) => { 

    const [state, dispatch] = useContext(PageContext);
    const [open, setModalIsOpen] = useState(false);

    let Component = state?.modal?.component
    let dynamicProps = state?.modal?.params
        
    useEffect(() => {   
        state?.modal && setModalIsOpen(state?.modal?.open);
      }
      // eslint-disable-next-line 
      , [state]); 

    function close() {
        setModalIsOpen(false);
        setTimeout(() => {
            dispatch({
                type: 'CLEAR_MODAL'
            })
        }, 600);
        
    }
    
    return (
        <>
            

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={close}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block  ">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-150"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 transition-opacity backdrop-filter backdrop-blur-sm " />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        className="inline-block align-bottom bg-white lg:mt-10 rounded-lg mx-2 text-left overflow-hidden  transform transition-all sm:align-top sm:max-w-4xl w-full  "
                        enter="ease-out duration-50"
                        enterFrom="opacity-0 translate-y-4 "
                        enterTo="opacity-100 translate-y-0 "
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100 translate-y-0 "
                        leaveTo="opacity-0 translate-y-4 "
                    >

                        
                        <div className=" align-bottom  text-left  transform transition-all overflow-hidden sm:align-middle sm:w-full  mt-16  rounded-lg lg:mb-10 ">
                            <div role="button" tabIndex={0} className="absolute top-2 right-2 mx-auto  z-50" onClick={() => close()} onKeyUp={() => close()}>
                                <button className="ml-1 flex items-center justify-center h-10 w-10 rounded-full  focus:outline-none  hover:bg-gray-200   text-gray-500  ">
                                    <span className="sr-only">Close sidebar</span>
                                
                                    <svg className="h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            {Component &&
                                <Component  
                                {...dynamicProps}
                                {...props}
                                />  
                            }
                        
                        </div>
                    </Transition.Child>
                    </div>
                </Dialog>
                </Transition.Root>
        </>
    );
}

