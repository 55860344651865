import React from "react";

export default React.createContext({
  products: [],
  cart: {open: false},
  lastProduct: {},
  menu: [],
  breadCrumbPath: [],
  addProductToCart: product => {},
  removeProductFromCart: productId => {},
  isLoggedIn: false, 
  token: null,
  userData: null,
  login: () => {},
  logout: () => {},
  modal: { open: false },
  miniCart: {open: false},
  storage: true,
  searchString: null,
});
