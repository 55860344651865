import React, { useContext, useEffect, useState } from "react"
import { Menu, Transition } from "@headlessui/react"  
import { AiOutlineUser } from "react-icons/ai" 
import { FiPower } from "react-icons/fi";


import PageContext from "../../context/PageContext";
import { Link } from "gatsby";
import { CheckIcon, CogIcon } from "@heroicons/react/solid"; 
import { Login } from "../layout/Login";

export const UserMenu = () => { 

  const [state, dispatch] = useContext(PageContext);
  const [userData, setUserData] = useState();
 
  useEffect(() => { 
    setUserData(JSON.parse(localStorage.getItem('userData')));
  }
  // eslint-disable-next-line 
  , []);

  function loginModal() {
    dispatch({
       type: 'SET_MODAL',
       payload: {
         component: Login,
         params: {          
           userData: userData
         }
       }
    }) 
    
  }

  return (
    <ul className="flex items-center justify-center">
      <li className="relative inline-block text-left"> 
        <Menu>
          {({ open }) => (
            <>
              <div
                className="flex rounded-md cursor-pointer focus:outline-none  " 
              >
               
                  {state?.isLoggedIn ?
                    <Menu.Button  
                    as="button"
                    href="#"
                    className={" cursor-pointer rounded-full bg-gray-700 px-2 py-2 hover:bg-gray-800 " + (open ? "bg-gray-200" : "")}
                    >  
                        <AiOutlineUser 
                              className={"h-6 w-6 text-white "}
                              placeholder="blurred"
                          
                          /> 
                          <div className="absolute top-0 -right-1.5 -mt-1.5  text-xs w-5 h-5 bg-green-700 text-white rounded-full flex items-center justify-center border border-white">
                            <span><CheckIcon className="w-3 h-3 text-white" /></span>
                          </div>
                    </Menu.Button>
                    :
                    <button onClick={loginModal}
                      className={" cursor-pointer rounded-full px-2 py-2 relative   hover:bg-white " + (open ? "bg-white" : "")}
                    > 
                          <AiOutlineUser 
                              className={"h-6 w-6 text-gray-600"}
                              placeholder="blurred"
                          />
                          
                      
                    </button>
                  }
                
              </div>

              <Transition
                show={open}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items 
                  static
                  className="absolute right-0 w-56 pb-10  origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                >
                {state?.isLoggedIn ? <>
                    <div className="px-4 py-3">
                        <p className="text-sm leading-5">Přihlášený uživatel</p>
                        <p className="text-sm font-medium leading-5 text-gray-900 truncate">
                        {userData?.email}
                        </p>
                    </div>             
                    <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                       <Link to={'/muj-ucet/kontakt'}
                       className={`${
                           active
                             ? "bg-gray-100 text-gray-900"
                             : "text-gray-700"
                         } flex space-x-2 w-full px-4 py-2 text-sm leading-5 text-left`}
                         >
                       <CogIcon className="w-5 h-5 opacity-50"  /><span>Nastavení účtu</span>
                       </Link> 
                      )}
                    </Menu.Item>
                     
                  </div>

                  <div className="py-1">
                    <Menu.Item>
                    {({ active }) => (
                        <button  onClick={() => state.logout()}
                        className={`${
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700"
                          } flex space-x-2 w-full px-4 py-2 text-sm leading-5 text-left`}
                          >
                        <FiPower className="w-5 h-5" /><span>Odhlásit</span>
                        </button> 
                    )}
                    </Menu.Item>
                  </div>
                    </>
                    :
                    ""
                
                }

                  
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </li>
    </ul>
  )
}
