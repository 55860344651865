import { HomeIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import React, { useContext } from "react"
import PageContext from "../../context/PageContext"

export const BreadCrumb = () => {
  const [context] = useContext(PageContext)
  const path = context?.breadCrumbPath

  return (
    <>
      <div className="  text-xs pt-2 ">
        <div className="max-w-7xl mx-auto px-5 pt-3">
          <div className="lg:px-5">
            <nav className="flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link
                      to={"/"}
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        className="flex-shrink-0 h-6 w-6 p-1"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </Link>
                  </div>
                </li>
                {path &&
                  path.map((page, pageIdx) => (
                    <li key={pageIdx}>
                      <div className="flex items-center">
                        <svg
                          className="flex-shrink-0 h-5 w-5 text-gray-300"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                        </svg>
                        {page.url ? (
                          <Link
                            to={page.url}
                            className="ml-4   text-gray-500 hover:text-gray-700"
                            aria-current={page.current ? "page" : undefined}
                          >
                            {page.menuName}
                          </Link>
                        ) : (
                          <div
                            className="ml-4   text-gray-500 hover:text-gray-700"
                            aria-current={page.current ? "page" : undefined}
                          >
                            {page.menuName}
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
