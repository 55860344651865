import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import PageContext from "../../context/PageContext"
import axiosInstance from "../../client"
import { BsBasket2Fill } from "react-icons/bs"
import { navigate } from "gatsby"

export const AddToCart = props => {
  const [context, dispatch] = useContext(PageContext)
  const [loading, setLoading] = useState(false)

  function addTo(product) {
    setLoading(true)

    dispatch({
      type: "ADD_PRODUCT",
      product: product,
    })
    dispatch({
      type: "OPEN_CART",
    })
    axiosInstance
      .post("cart/item_add/" + context?.cart?.cart?.cartHash + "/", {
        itemId: product._id,
        type: "goods",
        price: product.priceVat,
      })
      .then(res => {
        dispatch({ type: "SET_ITEMS", data: res.data })
        navigate("/vlozeno/")
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <>
      {loading ? (
        <div className="inline-flex space-x-2 items-center h-12 w-full justify-center px-4 py-2 text-white bg-gray-500 bg-opacity-60 rounded-md border border-gray-600  focus:outline-none">
          <svg
            className="animate-spin w-6 h-6 inline  "
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        <button
          onClick={() => addTo(props.item)}
          className="flex space-x-2 w-full justify-center items-center h-12 px-4 py-2 text-gray-100 bg-gradient-to-b from-green-600 to-green-700 rounded-md border border-green-800   hover:text-white hover:from-green-700 hover:to-green-800 focus:outline-none shadow-md"
        >
          <svg
            id="Capa_1"
            className="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 512 512"
          >
            <g>
              <path d="m497 181h-52.791l-115.496-144.37c-5.174-6.467-14.613-7.518-21.083-2.342-6.469 5.175-7.518 14.614-2.342 21.083l100.503 125.629h-299.582l100.504-125.629c5.175-6.469 4.126-15.909-2.342-21.083-6.47-5.176-15.909-4.126-21.083 2.342l-115.497 144.37h-52.791c-8.284 0-15 6.716-15 15v30c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-30c0-8.284-6.716-15-15-15z" />
              <path d="m90.577 470.121c1.84 6.44 7.726 10.879 14.423 10.879h302c6.697 0 12.583-4.439 14.423-10.879l56.891-199.121h-444.628zm234.127-66.586 15-90c1.362-8.172 9.09-13.696 17.262-12.33 8.172 1.362 13.692 9.09 12.33 17.262l-15 90c-1.223 7.337-7.578 12.537-14.778 12.537-.819 0-1.649-.067-2.484-.207-8.172-1.362-13.692-9.091-12.33-17.262zm-83.704-87.535c0-8.284 6.716-15 15-15s15 6.716 15 15v90c0 8.284-6.716 15-15 15s-15-6.716-15-15zm-84.966-14.795c8.173-1.361 15.9 4.158 17.262 12.33l15 90c1.362 8.171-4.158 15.9-12.33 17.262-.834.139-1.665.207-2.484.207-7.201 0-13.555-5.199-14.778-12.537l-15-90c-1.362-8.172 4.158-15.901 12.33-17.262z" />
            </g>
          </svg>
          <span className="truncate">Do košíku</span>
        </button>
      )}
    </>
  )
}

export const MiniCart = () => {
  const [context, dispatch] = useContext(PageContext)

  const count = context?.cart?.cart?.count
  const isOpen = context?.miniCart?.open

  useEffect(
    () => {
      if (isOpen) {
        setTimeout(() => {
          dispatch({
            type: "CLOSE_CART",
          })
        }, 6000)
      } else {
        //  closeRef?.current && closeRef.current.click()
      }
    },
    // eslint-disable-next-line
    [isOpen]
  )

  return (
    <>
      <ul className="flex items-center justify-center">
        <li className="relative inline-block text-left">
          <div className="flex rounded-md cursor-pointer focus:outline-none  ">
            <Link
              to={"/kosik/"}
              className={
                " cursor-pointer rounded-full p-2 relative  h-10 w-10 justify-center flex items-center  " +
                (count > 0
                  ? "bg-gray-700 hover:bg-gray-800 text-white "
                  : " hover:bg-white text-gray-600   ")
              }
            >
              <BsBasket2Fill className="w-5 h-5 " />
              {count > 0 && (
                <div className="absolute top-0 -right-1.5 -mt-1.5  text-xs w-5 h-5 bg-yellow-500 text-white rounded-full flex items-center justify-center border border-white">
                  <span>{count}</span>
                </div>
              )}
            </Link>
          </div>
        </li>
      </ul>

      {/* 
         <Transition
                show={true}
                className="fixed inset-0 z-50 flex items-start justify-center w-full sm:w-auto pointer-events-none sm:px-10 sm:items-start sm:justify-center top-1  " 
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transform ease-in duration-300 transition"
                leaveFrom="opacity-100 "
                leaveTo="opacity-0 translate-y-2 "
            > 
            <div class="sm:max-w-sm w-full bg-white shadow-lg text-sm font-medium rounded-md pointer-events-auto   border border-sky-400">
                <div class="p-4 relative">
                <div class=" justify-items-stretch">
                    
                    <div className="col-span-9">
                       
                          {cartItems && cartItems.length > 0 ?
                              cartItems.map((item) => (
                                 <div key={item.id} className="grid  grid-cols-5 ">
                                       
                                       <div className="p-5 flex items-center col-span-3 font-bold">
                                          {item.name}
                                       </div>
                                       <div className="p-5 flex items-center justify-end">
                                          <div className=" justify-end items-center lg:flex" >
                                                <button type="button" onClick={() => deleteItem(item.id)} className="text-gray-400  hover:text-gray-900 focus:outline-none py-3" title="Odstranit z košíku">
                                                      <XCircleIcon className="w-6 h-6  "  />
                                                </button>
                                             </div>
                                       </div>
                                    </div>   

                              ))
                                 : <EmptyList text={'Nákupní košík je prázdný'} />
                           }
                              
                           </div>         
                           {cartItems && cartItems.length > 0 &&
                           <div className="py-1 px-2 pt-4 grid md:grid-cols-2">
                              
                              
                                 <Link onClick={() => closeCart()} to={'/kosik/'} className="inline-flex space-x-4 items-center justify-between h-10 px-4 py-2 text-gray-100 bg-gradient-to-b from-green-600 to-green-700 rounded-md border border-green-800   hover:text-white hover:bg-amber-800 focus:outline-none shadow-md">
                                       <span>Zobrazit košík</span>
                                       <ChevronRightIcon className="w-5 h-5" />
                                 </Link>
                              
                           </div>
                           }
                        
                        </div>
                    
                    <button onClick={() => closeCart()} class="justify-self-end w-5 rounded-md inline-flex text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white ">
                        <span class="sr-only">Close</span>
                        
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                     
                   </div> 
            </div>
            </Transition> */}
    </>
  )
}
