import React from "react"
import { Link } from "gatsby"
import { Sticker } from "../elements/Sticker"
import { GatsbyImage } from "gatsby-plugin-image"

export const Product = ({
  item,
  list,
  itemIdx,
  addItem,
  hasMore,
  lastElementRef,
  location,
  searchString,
}) => {
  // let selectedClone = false;
  // if(item.clone) {
  //     selectedClone = item.id
  // }

  let sale = false
  if (item.supRrp > 0 && item.priceVat < item.supRrp) {
    sale = Math.floor(100 - (item.priceVat * 100) / item.supRrp)
  }
  const sections = item?.description?.split("<section>")
  const subSections = sections && sections[0].split("<subsection>")

  function addHistory() {
    if (location?.pathname !== "/vyhledavani/" && searchString) {
      window.history.pushState(
        { urlPath: "/vyhledavani/?s=" + searchString },
        "",
        "/vyhledavani/?s=" + searchString
      )
    }
  }

  // const onSubmit = async data => {
  //   try {
  //       addItem(data.id)
  //   } catch (err) {
  //     //loading = false
  //   }
  // };

  return (
    <Link
      to={"/produkty/detail/" + item.alias + "/"}
      onClick={() => addHistory("/produkty/detail/" + item.alias + "/")}
      key={item.id}
      className={
        "group relative bg-white  rounded-md overflow-hidden  " +
        (item.priceVat === 0 && "opacity-50")
      }
    >
      <div className="relative w-full sm:min-h-56 h-56 lg:min-h-72 lg:h-72 rounded-md flex justify-center items-center  ">
        <div className=" p-5">
          {item.imgData?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage
              image={item.imgData?.childImageSharp?.gatsbyImageData}
              alt={item.name}
            />
          )}
        </div>
        <div className="absolute top-0 right-0 left-0 bottom-0 inset-0 bg-black bg-opacity-5 w-full h-full group-hover:bg-opacity-10" />
        {item.priceVat === 0 && (
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full text-center flex items-end justify-center">
            <span className="p-5 bg-black bg-opacity-50 text-white font-bold w-full">
              Prodej ukončen
            </span>
          </div>
        )}
      </div>
      <div className="w-full">
        <div className="pt-3 px-5  ">
          <h3 className="font-bold text-gray-600 text-sm line-clamp-2 h-10">
            {item.name}
          </h3>
          <p className=" text-xs text-gray-600">
            {subSections && subSections[0]}
          </p>
          <div className="flex  text-xs justify-between items-center mt-1">
            {item.priceVat === 0 ? (
              <p className=" text-base text-yellow-600 font-bold"></p>
            ) : (
              <>
                <p className=" text-base text-sky-600 font-bold">
                  {item.priceVat.toLocaleString()} Kč
                </p>
                {sale && (
                  <p className="ml-3 line-through text-gray-700">
                    {item.supRrp.toLocaleString()} Kč
                  </p>
                )}
              </>
            )}
            {item.quantity > 0 ? (
              <>
                <div className="flex space-x-2 items-center ">
                  <span className="text-green-600 font-bold">skladem</span>
                </div>
              </>
            ) : (
              <div className="flex space-x-2 items-center text-gray-500 ">
                nedostupné
              </div>
            )}
          </div>
        </div>
      </div>
      {/*<p className="text-sm font-medium text-gray-900">{item.price_vat.toLocaleString()} Kč</p>*/}
      {/* <form onSubmit={handleSubmit(onSubmit)} className="flex items-center justify-end space-x-2 pr-2">
                      {item?.clones ? 
                        <select  {...register('id',{ required: true })} defaultValue={selectedClone}  className="shadow-sm focus:ring-theme-500 focus:border-theme-500 block sm:text-sm border-gray-300 rounded-md pr-8 ">
                          {item?.clones.map((clone,i) => (
                              <option key={i} value={clone.id}>{clone.name}</option>
                          ))}                                         
                        </select>
                      : <input type="hidden" {...register("id",{ required: true })} name="id" defaultValue={item.id} /> }
                      <button type="submit" className=" text-theme-600   hover:text-theme-800 flex items-center focus:outline-none">
                          <PlusCircleIcon className={"w-6 " + (loading && "opacity-50")} />
                      </button>
                               
                  </form> */}
      {list.length === parseInt(itemIdx + 1) && hasMore && (
        <span ref={lastElementRef}></span>
      )}
    </Link>
  )
}
