import React, { useContext, useEffect } from "react"
import { Product } from "./Product"
import { navigate } from "@reach/router"
import { LoadingProducts } from "./LoadingProducts"
import { useQuery } from "@apollo/client"
import { GOODS_LIST, GOODS_SEARCH } from "../../query/goods"
import ReactPaginate from "react-paginate"
import PageContext from "../../context/PageContext"
import { Link, useStaticQuery, graphql } from "gatsby"
import { SubCats } from "../layout/SubCats"
import { EmptyList } from "../elements/EmptyList"
import { Helmet } from "react-helmet"

export const ProductsList = ({
  cat,
  producer,
  page,
  params,
  orderBy,
  location,
  paging,
  hideHeader,
}) => {
  const fileData = useStaticQuery(graphql`
    query FileQuery {
      allProduct {
        nodes {
          id
          productId
          name
          localFile {
            childImageSharp {
              gatsbyImageData(height: 220)
            }
          }
        }
      }
    }
  `)

  const [context, dispatch] = useContext(PageContext)
  const searchString = context?.searchString
  const breadCrumb = context?.breadCrumbPath
  let title = "Švédské potraviny"
  if (breadCrumb && breadCrumb.length > 0) {
    breadCrumb.forEach(cat => {
      title = title + " > " + cat.menuName
    })
  }

  let order = []
  if (searchString) {
    order.push({ quantity: "DESC" })
    order.push({ priceVat: "DESC" })
    order.push({ updated: "DESC" })
  } else {
    order = orderBy
  }

  let queryName = GOODS_LIST
  if (searchString) {
    queryName = GOODS_SEARCH
  }

  const { loading, data } = useQuery(queryName, {
    variables: {
      cat: cat ?? null,
      itemsPerPage: paging,
      page: page,
      orderBy: order,
      search: searchString,
      params: params,
      storage: context?.storage ? 1 : 0,
    },
  })

  const handlePageClick = e => {
    let url = "/produkty/"
    const selectedPage = e.selected + 1
    if (cat) {
      url = url + cat + "/"
    }
    if (selectedPage === 1) {
      navigate(url + location.search)
    } else {
      navigate(url + "strana/" + selectedPage + "/" + location.search)
    }
  }

  let items = data?.goods.collection || []
  let newItems = []
  items &&
    items.forEach((item, itemIdx) => {
      let imgData
      fileData.allProduct.nodes.forEach(node => {
        if (parseInt(node.productId) === item._id) {
          imgData = node.localFile
        }
      })
      let newProduct = {
        ...item,
        imgData: imgData,
      }
      newItems.push(newProduct)
    })
  items = newItems
  const catData = cat && data?.goodCategories ? data?.goodCategories[0] : []
  const path = cat ? data?.goodCategoryGetPath : []
  const subCats = cat ? data?.goodCategoryFindAll : []
  const pageCount = data?.goods?.paginationInfo?.lastPage ?? 0

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | FÖDA</title>
      </Helmet>
      {/* <div>

            <ProductFilter cat={cat} catTags={params} params={params}  openStatus={openStatus} setOpenStatus={setOpenStatus} />
        </div>   */}

      {/* <div className={"mt-4 w-full text-gray-500 sm:mt-6 " + (!loading && items && items.length > 0 ? "block" : "hidden")}> */}
      <div className=" ">
        <div className=" ">
          <div className={" w-full   pt-5 "}>
            {!hideHeader && (
              <div className="  ">
                <div className="max-w-7xl mx-auto px-5">
                  <div className="flex justify-between w-full items-center h-14">
                    {!cat && !searchString ? (
                      <div className=" text-2xl lg:text-2xl lg:px-2 font-bold ">
                        Produkty {producer}
                      </div>
                    ) : searchString ? (
                      <div className=" text-2xl lg:text-2xl lg:px-2 font-bold">
                        {" "}
                        <Link
                          className="underline hover:no-underline"
                          to={"/vyhledavani/?s=" + searchString}
                        >
                          {searchString}
                        </Link>
                      </div>
                    ) : (
                      <>
                        <div className="  text-2xl lg:text-2xl lg:px-2 font-bold ">
                          {catData?.menuName}
                        </div>
                      </>
                    )}

                    {/* <div>
                      {!searchString && <StorageButton storage={storage} />}
                    </div> */}
                  </div>

                  {catData?.content && (
                    <div
                      dangerouslySetInnerHTML={{ __html: catData.content }}
                      className=" rounded-sm  py-5 prose-sm prose-amber   lg:px-2  text-gray-500 mx-auto  "
                    ></div>
                  )}
                  <div>
                    <SubCats cats={subCats} catData={catData} />
                  </div>
                </div>
              </div>
            )}

            {items && items.length === 0 && loading && <LoadingProducts />}
            {items && items.length === 0 && !loading && (
              <>
                {searchString ? (
                  <EmptyList
                    text={"Nic nebylo nalezeno, zkuste změnit zadání"}
                  />
                ) : (
                  <EmptyList text={"Žádné položky"} />
                )}
                {/* {storage && (
                  <>
                    <div className="mt-6 grid justify-center   max-w-7xl mx-auto  px-5">
                      <button onClick={storageChange}>
                        <ColorButton
                          text={"Zobrazit i produkty, kteří nejsou skladem"}
                        />
                      </button>
                    </div>
                  </>
                )} */}
              </>
            )}

            <div className="mt-6 grid grid-cols-2 gap-y-8 gap-x-6 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8 max-w-7xl mx-auto  px-5">
              {items &&
                items.length > 0 &&
                items.map((item, itemIdx) => (
                  <Product
                    key={itemIdx}
                    item={item}
                    list={items}
                    itemIdx={itemIdx}
                    hasMore={false}
                    lastElementRef={null}
                    location={location}
                    searchString={searchString}
                  />
                ))}
            </div>
          </div>

          {/* {items && items.length === 0 && !loading && <EmptyList text={'Žádné položky'} />}

            {error && <p>Error!</p>} */}

          {/* {loading && <Spin loading={loading} />} */}

          {!loading && pageCount > 1 && !hideHeader && (
            <div className="px-4 py-10 grid items-center justify-items-center mt-3  sm:px-6">
              <ReactPaginate
                previousLabel={
                  <>
                    <>
                      <svg
                        className="h-5 w-5 inline"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="inline-flex sm:hidden">Předchozí</span>
                    </>
                  </>
                }
                nextLabel={
                  <>
                    <span className="inline-flex sm:hidden">Následující</span>
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </>
                }
                previousClassName={
                  "relative rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none flex-1 flex justify-between " +
                  (page === 1 && "hidden")
                }
                previousLinkClassName={
                  " px-2 py-2  focus:outline-none  inline-flex items-center"
                }
                nextClassName={
                  "relative  rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none " +
                  (page === 1 && "rounded-l-md lg:rounded-l-none ") +
                  (page === pageCount && " hidden")
                }
                nextLinkClassName={
                  "px-2 py-2 focus:outline-none inline-flex items-center"
                }
                breakLabel={"..."}
                breakClassName={
                  "relative  sm:inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                }
                pageCount={pageCount}
                forcePage={page - 1}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={
                  "relative z-0 inline-flex  rounded-md shadow-sm -space-x-px select-none "
                }
                subContainerClassName={""}
                pageClassName={
                  "hidden sm:inline-flex relative    border border-gray-300 bg-white text-sm font-medium  hover:bg-gray-50 "
                }
                pageLinkClassName={"px-4 py-2 text-gray-700 focus:outline-none"}
                activeClassName={"bg-amber-200 hover:bg-amber-300 "}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProductsList
